import React from 'react'
import tick from './assets/tick.png'
import { PACKAGES, FAQS } from '../Config';
import { Link } from 'react-router-dom';
import { notification } from 'antd';

function HomePage() {

    const openNotificationSoldOut = () => {
        notification.warning({
            message: 'Ticket Limit Exceeded!',
            description:
                'Sorry, You are a bit late. We sold out all tickets',
        });
    };

    return (
        <div className='py-10 md:px-6 sm:px-12 xl:px-24 2xl:px-28'>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-20 px-4'>
                {/* box 1 */}
                {PACKAGES.map(p =>
                    <div className='border border-gray-100'>
                        <div className='bg-gray-50 text-center py-20 rounded'>
                            <h1 className='text-lg'>Hall {p.hall}</h1>
                            <h1 className='text-lg'>{p.name}</h1>
                            <h2 className='text-4xl mt-2 font-semibold'>₹ {p.amount}/-</h2>
                        </div>
                        <div className='py-10 px-10 md:px-20'>
                            {p.info.map(i =>
                                <div className='flex items-center mt-4'>
                                    <img src={tick} alt="tick" />
                                    <p className='text-lg ml-4'>{i}</p>
                                </div>
                            )}
                        </div>
                        <div className='flex justify-center'>
                            {/* <Link to={`/tickets/${p.slug}`} className=""> */}
                                <button
                                    style={{ backgroundColor: "#357C3C" }}
                                    className="p-3 text-white mt-6 w-44 md:w-96 rounded mb-10"
                                >
                                    <p className="m-0">Book Now</p>
                                </button>
                            {/* </Link> */}
                        </div>
                    </div>
                )}
            </div>

            {openNotificationSoldOut()}
            {/* 
            <div className='mt-10 px-4'>
                <p style={{ color: '#FF0000' }}>Note: No private/ commercial vehicles will be allowed inside the playing venue. <br /> Camera & Phone not allowed. </p>
            </div> */}

            <div className='mt-10 px-4'>
                <h1 className='text-3xl font-semibold'>Frequently Asked Questions (FAQ’s)</h1>

                {FAQS?.map(f =>
                    <div className='mt-6'>
                        <h2 className='font-semibold text-lg'>Q: {f.q}</h2>
                        <p className=' md:text-lg'>A: {f.a} </p>
                    </div>
                )}

            </div>
        </div>
    )
}

export default HomePage;
