import React, { useState, useEffect } from 'react'
import { Radio, Button, Form, Input, Upload, message, Select, notification, Typography, Divider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { API_URL, PACKAGES, COUNTRIES, ROUNDS} from '../Config';

const BookTicket = () => {
  const [documnet, setDocumnet] = useState();
  const [btnLoading, setbtnLoading] = useState(false);
  const [round, setRound] = useState("1");
  const [slot, setSlot] = useState('1');
  const [noOfTickets, setNoOfTickets] = useState('1');
  const history = useHistory();
  let { slug } = useParams();
  const ticket = PACKAGES.find(i => i.slug == slug);
  if (!ticket) return history.replace("/");
  const limit = true;


  const IDocumenttProps = {
    name: 'image',
    action: `${API_URL}contacts/add-image`,
    headers: {
      authorization: 'authorization-text'
    },
    data: {
      type: "contact_olympiad_document",
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const { response } = info?.file;
        const image = response?.image;
        if (image && image?.entity === "contact_olympiad_document") {
          setDocumnet(image.id);
        }
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        const errors = info?.file?.response?.errors;
        const { image } = errors;
        image?.map(i => {
          if (i == "The image may not be greater than 1024 kilobytes.") {
            return openNotification("error", `The image may not be greater than 1MB.`);
          } else {
            openNotification("error", `${i}`);
          }
        })
        // message.error(`${info.file.name} file upload failed.`);
        // openNotification("error", `${info.file.name} file upload failed.`);
      }
    }
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      olympiad_document: documnet ? documnet : null,
      amount: ticket.amount * noOfTickets,
      no_of_tickets: noOfTickets,
      ticket: ticket.slug,
      hall: ticket.hall,
      package: ticket,
      round:round,
      slot:slot
    };
    if (!data.olympiad_document) {
      message.error("Please upload mention document!");
      return;
    }
    handleSubmitForm(data);
  };

  const openNotification = (type, message) => {
    notification[type]({
      message: 'Upload Failed',
      description: message,
      duration: 0,
    });
  };


  const onChangeRound = (value) => {
    setRound(value);
  };

  const onChangeSlot = (e) => {
    setSlot(e.target.value);
  };

  const handleSubmitForm = async (values) => {
    const show = message.loading('Saving Values ...', 0);
    setbtnLoading(true);
    try {
      const { data } = await Axios.post(`olympiad-tickets`, values);
      setTimeout(show, 0);
      if (data?.contact && data?.order) {
        message.success("Data stored successfully.");
        setbtnLoading(false);
        history.push('/checkout', { order_id: data?.order?.id });
      }
    } catch (error) {
      setTimeout(show, 0);
      setbtnLoading(false);
      if (error?.response?.data?.errors) {
        const { email, mobile, olympiad_document } = error?.response?.data?.errors;
        if (email) message.warning(email?.[0]);
        if (mobile) message.warning(mobile?.[0]);
        if (olympiad_document) message.warning(olympiad_document?.[0]);
      }
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChangeTicketsNo = (e) => {
    setNoOfTickets(e);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout='vertical'
    >
      <div className='py-10 px-6 md:px-6 sm:px-12 xl:px-24 2xl:px-28'>
        <div className='md:flex'>
          <div className='md:w-8/12 md:pr-20'>
            <div>
              <h1 className='text-3xl font-semibold'>Book Ticket</h1>
            </div>
            <div className='mt-6'>
              {(ticket.slug != "group-hall-1" || ticket.slug != "group-hall-2") && <div className='mt-4'>
                <Form.Item
                  label="Select Round"
                  name="round"
                  initialValue={round}
                >
                  <Select size='large' onChange={onChangeRound}>
                    <Select.Option value="1">Round 1 - 29th Jul</Select.Option>
                    <Select.Option value="2">Round 2 - 30th Jul</Select.Option>
                    <Select.Option value="3">Round 3 - 31th Jul</Select.Option>
                    <Select.Option value="4">Round 4 - 01th Aug</Select.Option>
                    <Select.Option value="5">Round 5 - 02th Aug</Select.Option>
                    <Select.Option value="6">Round 6 - 03th Aug</Select.Option>
                    <Select.Option value="7">Round 7 - 05th Aug</Select.Option>
                    <Select.Option value="8">Round 8 - 06th Aug</Select.Option>
                    <Select.Option value="9">Round 9 - 07th Aug</Select.Option>
                    <Select.Option value="10">Round 10 - 08th Aug</Select.Option>
                    <Select.Option value="11">Round 11 - 09th Aug</Select.Option>
                  </Select>
                </Form.Item>
              </div>}
              {ticket.isfullDay != true && <div className='mt-4'>
                <Form.Item
                  label={"Select Slot"}
                  name="slot"
                  initialValue={slot}
                >
                  <Radio.Group buttonStyle="solid" size='large' onChange={onChangeSlot}>
                    <Radio.Button value="1"><b>Slot 1</b> <span style={{ fontSize: '0.9em' }}>{round == 11 ? "(10:15AM to 12:15PM)" : "(3:15PM to 5:15PM)"}</span></Radio.Button>
                    <Radio.Button value="2"><b>Slot 2</b> <span style={{ fontSize: '0.9em' }}>{round == 11 ? "(03.15PM to 05:15PM)" : "(5:15PM onwards)"}</span></Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>}

              {/* <Radio.Button value="1"><b>Slot 1</b> <span style={{ fontSize: '0.9em' }}>(3:15PM to 5:15PM)</span></Radio.Button>
                    <Radio.Button value="2"><b>Slot 2</b> <span style={{ fontSize: '0.9em' }}>(5:15PM onwards)</span></Radio.Button> */}

              {/* {round && <div className='mt-4'>
                <Form.Item
                  label="Number of tickets"
                  name="no_of_tickets"
                  initialValue={noOfTickets}
                >
                  <InputNumber min={1} max={200} defaultValue={1} onChange={onChangeTicketsNo} />
                </Form.Item>
              </div>} */}

              <div className='mt-4'>
                <Form.Item
                  label="Your Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={'email'}
                  label="Email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter valid email' }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Mobile Number"
                  name="mobile"
                  rules={[
                    { required: true, message: 'Please input your phone number!' },
                    { max: 13, min: 8, message: 'Please enter valid phone number!' }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Country Name"
                  name="country"
                  initialValue={"India"}
                  rules={[{ required: true, message: 'Please input your country name!' }]}
                >
                  <Select showSearch={true}>
                    {COUNTRIES.map(c =>
                      <Select.Option value={c.name}>{c.name}</Select.Option>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="City Name"
                  name="city"
                  rules={[{ required: true, message: 'Please input your city name!' }]}
                >
                  <Input />
                </Form.Item>
                <br />
                <h3>Govt ID Proof ({(ticket.slug == "group-hall-1" || ticket.slug == "group-hall-2") ? "" :"Aadhar/"} Passport Copy)</h3>
                <Upload {...IDocumenttProps} multiple={false} disabled={true}>
                  <Button type="default" icon={<UploadOutlined />} >
                    Click to upload
                  </Button>
                </Upload>
                <Typography>
                  <Typography.Paragraph style={{margin:'20px 0'}}>
                    <strong>Note:</strong><br />
                    <p>- The image may not be greater than 1024 kilobytes or 1 MB.</p>
                    <p>- Accept only jpeg,png,jpg and pdf file formats.</p>
                  </Typography.Paragraph>
                </Typography>
                <Divider />
              </div>
            </div>
          </div>

          <div className='md:w-4/12 bg-gray-50'>
            <div>
              <h1 className='text-center pt-10 text-xl font-semibold'>Ticket Summary</h1>

              <div>
                <div className='flex items-center justify-between px-4 mt-6 text-lg'>
                  <p>Type</p>
                  <p>{ticket.name}</p>
                </div>
                <hr className='my-4 mx-4 border-gray-200'></hr>
              </div>

              <div>
                <div className='flex items-center justify-between px-4 mt-6 text-lg'>
                  <p>Amount per ticket</p>
                  <p>₹ {ticket.amount}/-</p>
                </div>
                <hr className='my-4 mx-4 border-gray-200'></hr>
              </div>

              <div>
                <div className='flex items-center justify-between px-4 mt-6 text-lg'>
                  <p>Round</p>
                  <p>{round}</p>
                </div>
                <hr className='my-4 mx-4 border-gray-200'></hr>
              </div>

              <div>
                <div className='flex items-center justify-between px-4 mt-6 text-lg'>
                  <p>Number of tickets</p>
                  <p>{noOfTickets}</p>
                </div>
                <hr className='my-4 mx-4 border-gray-200'></hr>
              </div>

              <div>
                <div className='flex items-center justify-between px-4 mt-6 text-lg'>
                  <p>Net Pay</p>
                  <p>₹ {noOfTickets * ticket.amount}/-</p>
                </div>
                <hr className='my-4 mx-4 border-gray-200'></hr>
              </div>
            </div>
            <Button disabled={true} size="large" style={{ width: '80%', background: '#1890ff', margin: '0 10%' }} htmlType="submit" loading={btnLoading} type="primary">
              Pay Now
            </Button>

          </div>
        </div>
      </div>
      <div style={{ margin: '20px 0' }} />
    </Form>
  )
}

export default BookTicket;