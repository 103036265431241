
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from './components/HomePage';
import Header from './components/Header';
import BookTicket from './components/BookTicket';
import Checkout from './components/order/checkout';
import Axios from 'axios';
import { message } from 'antd';
import { API_URL } from './Config';
import PaymentStatus from './components/order/status';

Axios.defaults.headers.common['Accept'] = 'application/json';
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
Axios.defaults.baseURL = API_URL;

Axios.interceptors.request.use(
  async (config) => {
    const token = "";
    if (!token) {
      return config;
    }
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = 'application/json';
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    message.destroy();
    if (error.response?.data?.errors) {
      console.log(error.response.data.errors);
    } else {
      message.warning(error.response?.data?.message ?? error.message);
    }
    if (error.response?.status === 401) {
      console.log('401 error');
    //   authToken.clearAll();
    }
    return Promise.reject(error);
  }
);

function App() {
  return (
    <div className="App">
     <Router>
       <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/tickets/:slug" component={BookTicket} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/payment/status" component={PaymentStatus} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
