import React from 'react'
import header from './assets/olympiad-ticket.svg'
import mobile from './assets/mobile-banner.svg'

function Header() {
  return (
    <div className=''>
        <div className='md:hidden overflow-y-hidden'>
            <img className='w-full' src={mobile} alt="mobile"/>
        </div>
        <div className='hidden md:block'>
            <img className='w-full ' src={header} alt="header" />
        </div>
        <div className='py-2 sm:px-12 xl:px-24 2xl:px-28 bg-gray-100'>
            <div className='flex items-center'>
                <div className='ml-4'>
                    <a href='/'>
                        <p>Home</p>
                    </a>
                </div>
                <div className='ml-4'>
                    {/* <a href='/'>
                        <p>Verify Ticket</p>
                    </a> */}
                </div>
                <div className='ml-4'>
                    {/* <a href='/'>
                        <p>Support</p>
                    </a> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header